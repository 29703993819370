<template>
  <v-app>
    <drawer-menu v-if="logeado && usuario" :usuario="usuario" :rol="userRol" :drawer.sync="drawer" />
    <v-app-bar
      v-if="!isHome"
      :flat="!isFlat"
      :app="logeado"
      style="z-index: 9"
      max-height="57"
    >
      <v-app-bar-nav-icon v-if="logeado && $vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-avatar tile width="80">
        <appi-logo colorText="#D5D5D5" colorIcon="#D5D5D5" />
      </v-avatar>
      <span v-if="$vuetify.breakpoint.smAndUp" class="ml-1 text--disabled caption mt-4" >v4.1</span>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-spacer></v-spacer>
      <div
        v-if="logeado"
      >
      <notification-btn></notification-btn>
        <stock-btn v-if="userRol === 'root' || userRol === 'adm'"></stock-btn>
        <btn-estado-caja :apertura-caja="aperturaCaja"></btn-estado-caja>
        <printer-btn v-if="verImpresiones && $vuetify.breakpoint.smAndUp"></printer-btn>
        <v-btn
          v-show="!isNewC"
          depressed
          :disabled="!aperturaCaja"
          to="/comprobante"
        >
          <v-icon left small>fa-receipt</v-icon>
          Nuevo
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <footer-site v-if="isHome" />
    <v-fab-transition v-if="visibleGoTop">
      <v-btn
        v-show="isFlat"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        class="mb-10"
        color="primary" 
        @click="goTop"
      >
        <v-icon small>
          fa-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <no-service-available :errorType="noDataAvailable" />
    <update-app />
  </v-app>
</template>

<script>

import NotificationBtn from '@/components/notifications/NotificationBtn.vue'
import DrawerMenu from '@/components/base/DrawerMenu.vue'
import UpdateApp from '@/components/UpdateApp'
import { mapState, mapGetters,mapActions } from 'vuex'
import { onSnapshot, doc } from '@firebase/firestore'
import { getAuth, signOut } from 'firebase/auth'
import StockBtn from '@/components/stock/StockBtn.vue'
import { db } from '@/plugins/firebase'
import BtnEstadoCaja from '@/components/base/BtnEstadoCaja.vue'
import PrinterBtn from '@/components/prints/PrinterBtn.vue'
import FooterSite from '@/components/base/FooterSite.vue'
import AppiLogo from '@/components/img/AppiLogo.vue'
import NoServiceAvailable from '@/components/base/NoServiceAvailable.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppiLogo,
    FooterSite,
    DrawerMenu,
    StockBtn,
    UpdateApp,
    BtnEstadoCaja,
    PrinterBtn,
    NoServiceAvailable,
    NotificationBtn
  },
  data: () => ({
    isFlat: false,
    drawer: false,
    notificaciones: [],
    noGoTop: ['comprobante'],
    noDataAvailable: null,
    docUserRef: null,
    suscribeNegocio: null
  }),
  computed: {
    ...mapGetters(['negocio','logeado', 'userRol', 'aperturaCaja', 'user', 'userId']),
    ...mapState(['usuario']),
    isHome () {
      return this.$route.name === 'home'
    },
    isNewC () {
      return this.$route.name === 'comprobante'
    },
    verImpresiones () {
      try {
        return this.user.rutas.includes('prints')
      } catch {
        return false
      }
    },
    visibleGoTop () {
      try {
        return !this.noGoTop.includes(this.$route.name)
      } catch {
        return false
      }
    }
  },
  watch: {
    "negocio.colors" (newVal) {
      try {
        if (newVal) {
          this.$vuetify.theme.themes.light.primary = '#' + newVal.primary
          this.$vuetify.theme.themes.light.secondary = '#' + newVal.secondary
          this.$vuetify.theme.themes.light.info = '#' + newVal.info
          this.$vuetify.theme.themes.light.error = '#' + newVal.error
          this.$vuetify.theme.themes.light.warning = '#' + newVal.warning
          this.$vuetify.theme.themes.light.success = '#' + newVal.success
        }
      } catch {
        return
      }
    }
  },
  created () {
    this.getDataNegocio()
    this.watchDataCaja()
  },
  mounted () {
    if (!this.logeado && this.$route.name !== 'home') {
      this.$router.push('/')
    }
  },
  beforeDestroy () {
    if (this.suscribeNegocio) {
      this.suscribeNegocio()
    }
    if (this.docUserRef) {
      this.docUserRef()
    }
  },
  methods: {
    ...mapActions(['logout', 'setInfoUser', 'setInfoNegocio']),
    async getDataNegocio () {
      try {
        this.suscribeNegocio = onSnapshot(doc(db, 'negocio', '00001'), (doc) => {
          const dataNegocio = {...doc.data(), id: doc.id }
          this.setInfoNegocio({
            dataDetails: dataNegocio
          })
        },(error) => {
          console.error('ERROR NEGOCIO:', error)
          this.noDataAvailable = error.code
        })
      } catch (er) {
        console.error('DATA NEGOCIO: ', er)
      }
    },
    logOut () {
      const auth = getAuth()
      signOut(auth).then(() => {
        this.logout()
        this.$nextTick(()=>{
          this.$router.push('/')
        })
      }).catch(() => {
        this.logout()
        this.$nextTick(()=>{
          this.$router.push('/')
        })
      })
    },
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.isFlat = top > 150
    },
    goTop () {
      this.$vuetify.goTo(0)
    },
    async watchDataCaja () {
      try {
        if (this.logeado && this.userId) {
          this.docUserRef = onSnapshot(doc(db, 'users', this.userId), (doc) => {
            if (this.user.aperturaCaja !== doc.data().aperturaCaja) {
              this.setInfoUser({
                data: {
                  ...this.user,
                  aperturaCaja: doc.data().aperturaCaja,
                  cajaId: doc.data().cajaId,
                  fechaCaja: doc.data().fechaCaja
                }
              })
            }
          }, (error) => {
            console.error('ERROR CAJA:', error)
          })
        }
      } catch (er) {
        console.error('ERROR CAJA:', er)
      }
    }
  }
}
</script>
<style>
</style>
