<template>
  <v-avatar
    :size="sizeAvatar"
    class="my-2"
    :class="!rounded ? 'rounded':''"
    >
    <v-img
      eager
      referrerpolicy="no-referrer"
      v-bind:src="photoURL"
      lazy-src="/no_image.png"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0" align="center" justify="center"
        >
          <v-progress-circular
            indeterminate
            size="30"
            width="2"
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-avatar>
</template>
<script>
import imageFunction from '@/functions/imageFunction'
export default {
  name: 'AvatarView',
  props: {
    sizeAvatar: {
      type: String,
      require: false,
      default: '48'
    },
    fileName: {
      type: String,
      require: false,
      default: null
    },
    fileExt: {
      type: String,
      require: false,
      default: '.webp'
    },
    sizeImg: {
      type: String,
      require: false,
      default: 'xs'
    },
    rounded: {
      type: Boolean,
      require: false,
      default: false
    },
    store: {
      type: String,
      require: true
    },
    refresh: {
      type: Boolean,
    }
  },
  data: () => ({
    photoURL: null,
    refreshImg: false
  }),
  watch: {
		refresh (newVal) {
			if (newVal) {
				this.refreshImg = true
        this.getImage()
			}
		},
		refreshImg (newVal) {
			if (!newVal) {
				this.$emit('update:refresh', false)
			}
		}
	},
  mounted () {
    this.getImage()
  },
  methods: {
    async getImage () {
			this.photoURL = await imageFunction.getImageStore(this.store, this.fileName, this.fileExt, this.sizeImg)
      setTimeout(() => {
        this.refreshImg = false
      }, 1000);
		}
  }
}
</script>
<style>
</style>
