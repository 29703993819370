import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { firebase } from './plugins/firebase'
import './plugins/filters'
import VueCountdown from '@chenfengyuan/vue-countdown'
import createStore from './store'
import VueHtml2pdf from 'vue-html2pdf'
import '@fortawesome/fontawesome-free/css/all.css'
import './plugins/vuetify-mask'
import './plugins/apexchart'
// import './plugins/axios'
import './moment'
import './vee-validate'
import 'devextreme/dist/css/dx.light.css';
import './plugins/axios'
import NotificationToast from '@/components/base/NotificationsToast'

Vue.config.productionTip = false

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  },
})

Vue.directive('sritext', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /[^a-zA-Z0-9\s\-_\/]/g;
      e.target.value = e.target.value.replace(regex, '')
      vnode.componentInstance.$emit('input', e.target.value)
    })
  },
})

Vue.use(VueHtml2pdf)
Vue.component('vue-countdown', VueCountdown)
Vue.component('vue-notifications', NotificationToast)

createStore().then(store => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  
})

export default { firebase }
